<template>
  <div class="list-table">
    <AdminProcessModal
      v-if="$route.path.toLowerCase().includes('administrador')"
      :fileInfo="fileInfo"
    />
    <BankProcessModal
      :bankName="filtersParams.bank"
      :fileInfo="fileInfo"
      :showModal="showModal"
      @closeModal="showModal = false"
    />
    <el-card class="box-card">
      <h4>Filtrar</h4>
      <hr />
      <label style="margin-bottom: 0.5rem; display: flex">Fecha</label>
      <div class="date">
        <div class="block">
          <span class="demonstration">Desde</span>
          <el-date-picker
            required
            title="Este campo es obligatorio"
            aria-label="required"
            v-model="date.init_created_at"
            align="center"
            size="sm"
            lang="es"
            type="date"
            placeholder="Fecha inicial"
          >
          </el-date-picker>
        </div>
        <div class="block">
          <span class="demonstration">Hasta</span>
          <el-date-picker
            required
            title="Este campo es obligatorio"
            aria-label="required"
            v-model="date.end_created_at"
            align="center"
            size="sm"
            lang="es"
            type="date"
            placeholder="Fecha final"
          >
          </el-date-picker>
        </div>
      </div>
      <hr />
      <div v-if="$route.path.toLowerCase().includes('administrador')">
        <div>
          <label style="margin-bottom: 0.5rem; display: flex"
            >Tipo de archivo</label
          >
          <el-checkbox-group v-model="filtersParams.type">
            <el-checkbox
              v-for="(type, index) in Object.entries({
                ...checkListType['bancolombia'],
                ...checkListType['santander'],
                ...checkListType['bbva'],
              })"
              :key="index"
              :label="type[0]"
              @change="applyFilter()"
              >{{ type[1] }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div v-else>
        <label style="margin-bottom: 0.5rem; display: flex"
          >Tipo de archivo</label
        >
        <el-checkbox-group v-model="filtersParams.type">
          <el-checkbox
            v-for="(type, index) in Object.entries(
              checkListType[$route.params.bankName]
            )"
            :key="index"
            :label="type[0]"
            @change="applyFilter()"
            >{{ type[1] }}</el-checkbox
          >
        </el-checkbox-group>
      </div>

      <hr />
      <div>
        <label style="margin-bottom: 0.5rem; display: flex">Estado</label>
        <el-radio-group v-model="filtersParams.status">
          <el-radio
            v-for="(item, index) in radioStatus"
            :key="index"
            :label="item.value"
            @change="applyFilter()"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </div>
      <hr />
    </el-card>
    <Loading v-if="isLoading" />
    <DataTable
      v-show="!isLoading"
      :loading="isLoading"
      :value="filesArray"
      :lazy="true"
      :paginator="true"
      class="p-datatable-customers"
      :first="0"
      :rows="20"
      :rowHover="true"
      editMode="column"
      :selection.sync="selectedFilesArray"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[20, 60, 100]"
      currentPageReportTemplate="Página {first} a la {last} de {totalRecords} Entradas"
      :totalRecords="filesCount"
      @page="onPage($event)"
    >
      <div class="header-button">
        <Button v-b-modal.uploadModal type="submit" class="upload-button"
          ><i class="fas fa-upload fa-1x" style="color: white; margin: 5px"></i>
          <br />
          <span class="nav--item--name">Cargar</span></Button
        >
      </div>
      <template>
        <div class="table-header-button">
          <div>
            <Button
              @click="deleteFiles()"
              class="upload-button"
              label="Borrar"
              icon="pi pi-trash"
            ></Button>
          </div>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filtersParams.name"
              placeholder="Buscar"
              @change="applyFilter()"
              type="text"
            ></InputText>
          </span>
        </div>
      </template>
      <template #empty> No se encuentra la busqueda. </template>
      <template #loading>
        Buscando en la base de datos. Por favor espere.
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
      <Column field="process_id" header="PROCESO ID">
        <template #body="slotProps">
          {{ slotProps.data.process_id[0].number }}
        </template>
      </Column>
      <Column field="name" header="NOMBRE">
        <template #body="slotProps">
          {{ slotProps.data.name.split("/").reverse()[0] }}
        </template>
      </Column>
      <Column field="type" header="TIPO">
        <template #body="slotProps">
          {{ slotProps.data.type.split("_").join(" ") }}
        </template>
      </Column>
      <Column field="agent" header="USUARIO">
        <template #body="slotProps">
          {{ slotProps.data.user.length !== 0 ? slotProps.data.user[0].name : 'Sin usuario' }}
        </template>
      </Column>
      <Column field="loaded" header="CARGADO">
        <template #body="slotProps">
          {{ slotProps.data.created_at | formatDate }}
        </template>
      </Column>
      <Column field="state" header="ESTADO">
        <template #body="slotProps">
          <div
            :style="slotProps.data.status == 'Sin procesar' ? 'color:red' : ''"
          >
            {{ slotProps.data.status }}
          </div></template
        >
      </Column>
      <Column field="process" header="PROCESAR">
        <template #body="slotProps">
          <b-button
            v-if="
              $route.path.toLowerCase().includes('administrador') &&
              slotProps.data.status != 'procesado'
            "
            v-b-modal.admin-modal
            class="gmv-download-button"
            type="submit"
            @click="getRouteFiles(slotProps.data)"
          >
            <i class="fas fa-paper-plane fa-2x"></i
          ></b-button>
          <b-button
            v-if="
              $route.path.toLowerCase().includes('bancolombia') &&
              slotProps.data.status != 'procesado'
            "
            v-b-modal.bancolombia-modal
            class="gmv-download-button"
            type="submit"
            @click="getRouteFiles(slotProps.data)"
          >
            <i class="fas fa-paper-plane fa-2x"></i
          ></b-button>
          <b-button
            v-if="
              $route.path.toLowerCase().includes('santander') &&
              slotProps.data.status != 'procesado'
            "
            v-b-modal.santander-modal
            class="gmv-download-button"
            type="submit"
            @click="getRouteFiles(slotProps.data)"
          >
            <i class="fas fa-paper-plane"></i
          ></b-button>
          <b-button
            v-if="
              $route.path.toLowerCase().includes('bbva') &&
              slotProps.data.status != 'procesado'
            "
            v-b-modal.bbva-modal
            class="gmv-download-button"
            type="submit"
            @click="getRouteFiles(slotProps.data)"
          >
            <i class="fas fa-paper-plane"></i
          ></b-button>
          <span v-if="slotProps.data.status == 'procesado'">
            <i class="fas fa-check" style="color: green !important"></i>
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BankProcessModal from "@/components/BankProcessModal.vue";
import AdminProcessModal from "@/components/AdminProcessModal.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Loading from "@/components/Loading";
import moment from "moment";
export default {
  inject: ["reload"],
  name: "ProcessFiles",
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    Loading,
    AdminProcessModal,
    BankProcessModal,
  },
  data() {
    return {
      fileInfo: {},
      showModal: false,
      checkedFileType: [],
      filtersParams: {
        skip: 0,
        limit: 20,
        bucket_type: "input",
        name: null,
        type: [],
        bank: [localStorage.getItem("selectedBank")],
        status: null,
        init_created_at: null,
        end_created_at: null,
      },
      checkListType: {
        bancolombia: {
          contener: "Contener",
          revolvente: "Revolvente",
          desmontes: "Desmontes",
          status: "Status",
          rechazos_simulacion: "Rechazos simulación",
        },
        santander: {
          telefonos: "Teléfonos",
          asignaciones: "Asignaciones",
          actualizaciones: "Actualizaciones",
          desasignaciones: "Desasignaciones",
        },
        bbva: {
          pre_aprobados: "Preaprobados",
        },
      },
      radioStatus: [
        { name: "Sin procesar", value: "Sin procesar" },
        { name: "Procesado", value: "procesado" },
      ],
      date: {
        init_created_at: null,
        end_created_at: null,
      },
      isLoading: false,
      banksArray: [],
      parametersBanks: null,
      filters: {},
      filesArray: null,
      selectedFilesArray: null,
    };
  },
  watch: {
    date: {
      deep: true,
      handler() {
        this.renderDates();
      },
    },
  },
  async created() {
    let banks = this.$store.getters["banks/showBanks"];
    if (!banks) {
      const response = await this.$store.dispatch("banks/getBanks");
      banks = response.data;
    }
    this.banksArray = banks;
    this.parametersBanks = this.banksArray[0];
    this.applyFilter();
  },

  computed: {
    ...mapGetters({ filesCount: "files/showFilesCount" }),
  },

  methods: {
    async renderDates() {
      if (
        this.date.init_created_at != null &&
        this.date.end_created_at != null
      ) {
        this.filtersParams.init_created_at = moment(
          String(this.date.init_created_at)
        ).format("YYYY-MM-DD");
        this.filtersParams.end_created_at = moment(
          String(this.date.end_created_at)
        ).format("YYYY-MM-DD");
        this.applyFilter();
      } else if (
        this.date.init_created_at == null &&
        this.date.end_created_at == null
      ) {
        this.filtersParams.init_created_at = null;
        this.filtersParams.end_created_at = null;
        this.applyFilter();
      }
    },
    getRouteFiles(data) {
      this.fileInfo = data;
      this.showModal = true;
    },

    async getFiles() {
      this.isLoading = true;
      let response = await this.$store.dispatch(
        "files/getFiles",
        this.filtersParams
      );
      this.uploaderValidation(response.data);
      response.data.forEach((item, index) => {
        if (item.process_id.length == 0) {
          response.data[index].process_id.push({ number: "Sin Procesar" });
        }
      });
      this.filesArray = response.data;
      this.filesArray = this.filesArray.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      this.isLoading = false;
    },

    uploaderValidation(data) {
      let bankName = localStorage.getItem("selectedBank");
      if (data.length === 0) {
        this.$router.push({
          name: "Uploader",
          params: { bankName: bankName },
        });
      }
    },
    async deleteFiles() {
      this.swal({
        title: "¿Estas Seguro?",
        text: "Una vez eliminado, ¡no podrá recuperar este archivo!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          for (let i in this.selectedFilesArray) {
            let result = await this.$store.dispatch(
              "files/deleteFiles",
              this.selectedFilesArray[i].id
            );
            if (result.status == 204) {
              this.reload({ name: "Bancolombia" });
            }
          }
        }
      });
    },

    async applyFilter() {
      await this.$store.dispatch("files/getFilesCount", this.filtersParams);
      this.getFiles();
    },

    onPage(event) {
      this.filtersParams.skip = event.first;
      this.filtersParams.limit = event.rows;
      this.getFiles();
    },
  },
};
</script>
<style lang="scss" scoped>
@include upload-button;
.list-table {
  display: flex;
  padding-top: 10px;
  width: 100%;
  height: 100%;
}
::v-deep .p-datatable-wrapper {
  padding: 0px;
  height: 73%;
  width: 100%;
  overflow: auto;
}
a {
  text-decoration: none;
}
.fa-2x {
  font-size: 1.5em;
}
::v-deep .el-card__body {
  padding: 0px;
  height: 100%;
  overflow: auto;
}
::v-deep .el-card {
  border-radius: 10px;
  min-width: 240px;
  height: 100%;
  background: #f2f2f2;
}
.demonstration {
  float: left;
  font-style: italic;
  width: 100%;
  text-align: start;
}
.filter-button {
  display: flex;
}
.date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
::v-deep .el-checkbox-group {
  font-size: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
::v-deep .el-checkbox__label:first-letter {
  text-transform: uppercase;
}
::v-deep .el-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
::v-deep .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #dc3545;
}
::v-deep .p-datatable {
  width: 85%;
  padding: 0rem 1rem 1rem 1rem;
  overflow: auto;
}
::v-deep .p-datatable table {
  width: 100%;
  table-layout: auto;
  border: 1px solid lightgray;
  box-shadow: 0 6px 8px 0 #cecece, 0 3px 3px -2px #cecece, 0 1px 8px 0 #cecece;
}
::v-deep .p-datatable .p-sortable-column.p-highlight:hover {
  background: #dee2e6;
  color: #dc3545;
}
::v-deep .p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}
::v-deep .p-paginator {
  .p-paginator-current {
    margin-left: auto;
    color: white;
  }
  .pi {
    color: white;
  }
}
::v-deep .btn-group > .btn,
.btn-group-vertical > .btn {
  flex: 1 1 auto;
}
::v-deep .p-datatable.p-datatable-customers .p-paginator {
  background-color: #b3282d;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page {
  color: #2a2b2c;
  background-color: #b3282d;
}
::v-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: white;
}
.p-input-icon-left {
  display: flex;
}
.p-input-icon-left > .p-inputtext {
  padding-left: 2rem;
  width: 20rem;
}
::v-deep .p-inputtext:enabled:hover {
  border-color: red;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #d32a2d;
  background: #d32a2d;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ff6c6c;
  border-color: #f32121;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #d32a2d;
}
::v-deep
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  border-color: #d32a2d;
  background: #d32a2d;
  color: #ffffff;
}
::v-deep .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: black;
  background-color: white;
}
::v-deep .p-checkbox .p-checkbox-box {
  border: 2px solid rgb(160, 158, 158);
}
::v-deep .p-progressbar {
  height: 0.5rem;
  background-color: white;
}
.header-button {
  display: flex;
  justify-content: flex-end;
}
.table-header-button {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  justify-content: space-between;
  background: #2a2b2c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -2px 8px 3px #cecece, 0px 0px 8px 1px #cecece;
}
::v-deep .p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 23.6px;
    text-align: center;
    background-color: #afafaf;
  }
  .p-paginator {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 6px 8px 0 #cecece, 0 3px 3px -2px #cecece, 0 1px 8px 0 #cecece;
  }
  .p-datatable-thead > tr > th {
    text-align: center;
  }
  .p-datatable-tbody > tr > td:first-letter {
    cursor: auto;
    text-transform: uppercase;
  }
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
::v-deep .p-datatable .p-datatable-tbody > tr > td {
  text-align: center;
  color: black;
}
::v-deep .p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #d9d9df;
}
::v-deep .p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #d1d1d3;
  color: #495057;
}
::v-deep .p-datatable .p-datatable-thead > tr > th {
  background-color: #dee2e6;
  color: black;
  border-top: 1px solid #909399;
  &:hover {
    background-color: #dee2e6;
  }
}
::v-deep .pi-sort-alt:before {
  color: black;
}
.gmv-download-button {
  background-color: transparent;
  color: black;
}
.filter-button {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 64em) {
  v-deep .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: center;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: center;
        clear: center;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
